import React from "react"
import styled from "styled-components"
import Collapsible from "react-collapsible"
import CollapsibleItem from "../components/colllapsibleItemFaq"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Section = styled.section`
  background: var(--grey);
  h1 {
    text-transform: uppercase;
    font-size: 3.6rem;
    font-weight: 400;
    margin-bottom: 6rem;
    font-family: "glacial_regular";
    span {
      font-family: "glacial_bold";
    }
  }
  h4 {
    font-size: 2rem;
  }
  p,
  li {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  ul {
    list-style-type: square;
    list-style-position: inside;
    padding-bottom: 1.6rem;
  }
  li {
    padding-bottom: 1rem;
  }
  hr {
    color: var(--beige);
    width: 70%;
    border: 1px solid;
    margin: 4rem auto 5rem;
  }
`

const FaqContent = styled.div`
  margin: 3rem 0 0;
  .Collapsible__trigger {
    display: block;
    cursor: pointer;
    margin: 1.6rem 0;
    border-left: 2px solid var(--beige);
    padding: 1rem;
    svg {
      transition: transform 350ms;
    }
    &.is-open svg {
      transform: rotateZ(90deg);
    }
  }
`

const Faq = () => {
  return (
    <Layout>
      <Seo
        title="Perguntas Frequentes"
        description="Perguntas frequentes sobre os produtos Boomsy. Todas as informações para encomendares as nossas pestanas e eyeliner magnético."
      />
      <Section>
        <div className="container" id="faq">
          <h1 className="center">
            <span>Perguntas</span> Frequentes
          </h1>
          <FaqContent>
            <Collapsible
              trigger={
                <CollapsibleItem text="Que métodos de pagamento aceitam?" />
              }
              contentContainerTagName="div"
              open
            >
              <p>
                Podes pagar com VISA, Mastercard, American Express, MB Way,
                Transferência Bancária ou PayPal.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <CollapsibleItem text="Quanto tempo demoro a receber os produtos em minha casa?" />
              }
              contentContainerTagName="div"
            >
              <p>
                As encomendas são enviadas no prazo de 24 horas úteis após
                recepção do pagamento, por CTT correio registado.
              </p>
              <p>
                Uma vez enviada a encomenda, deverás recebê-la dentro de 2 dias
                úteis.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <CollapsibleItem text="Terei de pagar pelo envio da minha encomenda?" />
              }
              contentContainerTagName="div"
            >
              <p>
                Se a tua compra for superior a 40€, não terás de pagar portes de
                envio.
              </p>
              <p>
                Para encomendas inferiores a 40€, os portes de envio terão um
                custo de €3.45.
              </p>
            </Collapsible>
          </FaqContent>
          <hr />
          <FaqContent>
            <Collapsible
              trigger={
                <CollapsibleItem text="Quanto tempo dura o Eyeliner ou as minhas Pestanas Magnéticas Boomsy?" />
              }
              contentContainerTagName="div"
            >
              <p>
                Se tomares bem conta das tuas pestanas, elas deverão durar para
                mais de 35 utilizações.
              </p>
              <p>
                Depois de cada utilização, certifica-te que limpas os ímans das
                tuas pestanas e que as voltas a colocar na respetiva caixa.
                Nunca limpes os ímans com óleo, visto que poderão perder
                magnetismo.
              </p>
              <p>
                Quanto ao Eyeliner, dura até 6 meses após abertura, permitindo
                60 utilizações (aproximadamente). Garante que fechas bem o teu
                eyeliner após cada aplicação, para que este não seque.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <CollapsibleItem text="O Eyeliner é seguro? Quais são os seus ingredientes?" />
              }
              contentContainerTagName="div"
              containerElementProps={{ id: "eyelinerfaq" }}
            >
              <p>
                O nosso eyeliner tem os seguintes ingredientes, todos
                considerados seguros e não-tóxicos. São frequentemente
                encontrados em produtos de cosmética e todos eles são{" "}
                <em>cruelty free</em>:
              </p>
              <ul>
                <li>ISODODECANE</li>
                <li>VP/VA COPOLYMER</li>
                <li>TRIMETHYLSILOXYPHENYL DIMETHICONE</li>
                <li>PHENOXYETHANOL</li>
                <li>DIAZOLIDINYL UREA</li>
                <li>CI 77499</li>
              </ul>
              <p>
                O ingrediente que torna possível a magnetização é o CI 77499
                (óxido de ferro), que é{" "}
                <a
                  href="https://www.ewg.org/skindeep/ingredients/706410-IRON_OXIDES/"
                  target="_blank"
                  rel="noreferrer"
                >
                  aprovado pelo Environmental Working Group
                </a>
                .
              </p>
              <p>
                O{" "}
                <a
                  href="http://www.princetonconsumer.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Princeton Consumer Research
                </a>{" "}
                realizou também um estudo independente que não detectou qualquer
                reacção adversa ao uso de pestanas magnéticas em sujeitos do
                sexo feminino saudáveis.
              </p>
              <p>
                Ainda assim, e para máxima segurança dos nossos consumidores,
                não recomendamos o uso de produtos para os olhos em qualquer
                pessoa com condições oculares pré-existentes.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <CollapsibleItem text="Posso usar máscara com as minhas Pestanas Magnéticas Boomsy?" />
              }
              contentContainerTagName="div"
            >
              <p>
                Sim. Recomendamos que apliques a máscara antes de aplicares as
                tuas pestanas magnéticas.
              </p>
              <p>
                É importante que não coloques a máscara diretamente nas pestanas
                magnéticas, visto que poderá afetar o tempo de vida das tuas
                pestanas Boomsy.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <CollapsibleItem
                  text="Como aplico e garanto a máxima fixação das minhas Pestanas
              Magnéticas Boomsy?"
                />
              }
              contentContainerTagName="div"
            >
              <p>
                Primeiramente, deves garantir que a tua pálpebra está limpa e
                sem oleosidade. De seguida, deverás aplicar o eyeliner e esperar
                cerca de 2 minutos.
              </p>
              <p>
                É muito importante que não deixes que o eyeliner seque
                totalmente - o eyeliner deverá estar ligeiramente húmido quando
                colocares as pestanas. Para maior fixação, podes ainda aplicar
                uma segunda camada.
              </p>
              <p>
                Se estiveres a reutilizar as tuas pestanas, certifica-te que não
                tens vestígios de máscara ou eyeliner nos ímanes. Se notares que
                estão sujos, utiliza um desmaquilhante sem óleo para os limpar.
              </p>
              <p>
                Quando aplicares as tuas pestanas magnéticas Boomsy, começa de
                dentro para fora, pressionando-as 2–3 vezes ao longo da linha
                das pestanas para máxima fixação.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <CollapsibleItem text="Como retiro e limpo as minhas Pestanas Magnéticas Boomsy?" />
              }
              contentContainerTagName="div"
            >
              <p>
                A remoção das nossas pestanas é muito mais simples que as
                pestanas com cola convencionais. Basta puxá-las. As tuas
                pestanas naturais ficarão intactas.
              </p>
              <p>
                Poderás remover o eyeliner da tua pálpebra com desmaquilhante à
                base de óleo ou água micelar. Se pretenderes limpar e remover o
                eyeliner das tuas pestanas magnéticas, podes utilizar qualquer
                desmaquilhante sem óleo.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <CollapsibleItem text="As Pestanas Magnéticas são à prova de água e resistentes ao vento?" />
              }
              contentContainerTagName="div"
            >
              <p>
                Sim, poderás utilizar as tuas pestanas magnéticas Boomsy no
                ginásio ou à chuva, que a fixação está garantida. No entanto,
                não as deves levar para o banho ou para a piscina.
              </p>
              <p>
                As nossas pestanas aguentam rajadas de vento de até 120 km/h.
              </p>
            </Collapsible>
            <Collapsible
              trigger={
                <CollapsibleItem
                  text="A banda das minhas Pestanas Boomsy é muito grande. O que posso
              fazer?"
                />
              }
              contentContainerTagName="div"
            >
              <p>
                Como cada olho é diferente, não podemos garantir um tamanho
                universal para as pestanas. Como tal, se tiveres necessidade de
                reduzir o tamanho das tuas pestanas, poderás utilizar uma
                tesoura para cortar as extremidades da pestana, até ao íman.
              </p>
              <p>
                Como alternativa, poderás também considerar as nossas pestanas
                Boomsy “Air” que têm apenas 28mm de comprimento, ao contrário
                dos habituais 32mm.
              </p>
            </Collapsible>
          </FaqContent>
          <hr />
          <p>
            Tens alguma dúvida que não tenha sido respondida? Contacta-nos
            através do <a href="mailto:info@boomsy.pt">info@boomsy.pt</a> ou
            através do nosso{" "}
            <a
              href="https://www.instagram.com/boomsylashes/"
              target="_blank"
              rel="noreferrer"
            >
              Instagram
            </a>
            .
          </p>
        </div>
      </Section>
    </Layout>
  )
}

export default Faq
